import axios from 'axios';
import tool from './tool';
import router from './../router/index';
import {
	ElMessage
} from 'element-plus';

axios.defaults.baseURL = '/screen'

axios.defaults.timeout = 100000

// HTTP request 拦截器
axios.interceptors.request.use(
	(config) => {
		config.headers['Tit-token'] = localStorage.getItem('Tit-token')
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);
let status = true;
// HTTP response 拦截器
axios.interceptors.response.use(
	(response) => {
		if (response.data.code == 401 && status) {
			ElMessage.warning({
				// title: '登录过期',
				message: "登录过期，请重新登录！"
			});
			status = false;
			router.replace({
				path: '/'
			});
			axios.post('/sso/logout');
			tool.data.remove("user");
			localStorage.removeItem("token");
		}
		return response;
	},
	(error) => {
		if (error.response.status == 404 && status) {
			ElMessage.error({
				// title: '请求错误',
				message: "Status:404，正在请求不存在的服务器记录！"
			});
			status = false;
		}
		if (error.response.status == 500 && status) {
			ElMessage.error({
				// title: '请求错误',
				message: "Status:500，服务器发生错误！"
			});
			status = false;
		}

		return Promise.reject(error.response);
	}
);

var http = {

	/** get 请求
	 * @param  {接口地址} url
	 * @param  {请求参数} params
	 */
	get: function (url, params) {
		return new Promise((resolve, reject) => {
			axios.get(url, {
					params: params
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		})
	},

	/** post 请求
	 * @param  {接口地址} url
	 * @param  {请求参数} params
	 */
	post: function (url, params) {
		return new Promise((resolve, reject) => {
			axios.post(url, params)
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		})
	}
}

export default http;
