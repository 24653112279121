<template>
    <div class="home">
        <div class="header glo-relative">
            <div class="title">新科智慧云平台</div>
            <div class="date-time">
                <div>{{dateTime.date}} {{dateTime.time}} <span class="week" v-if="dateTime.week">(星期{{dateTime.week}})</span></div>
            </div>
        </div>
        <div class="content">
            <div class="left" 
                v-loading="loading"
                element-loading-background="rgba(44, 45, 68,0.68)">
                <div class="container">
                    <div :class="{'title':true,'title-active':active.dev === index}" 
                        v-for="(item,index) in navList" :key="index" @click="selectDev(item,index)">
                        ● {{item.meta.title || '无'}}
                    </div>
                </div>
            </div>
            <div class="middle">
                <div class="bottom glo-relative">
                    <div class="container glo-relative">
                        <img class="img" src="../../assets/img/home1.png">
                        <div class="point" v-for="(item,index) in devPointData" :key="index" :style="{top:item.top,left:item.left}" @click="goItem(item)">
                            <img :class="{'tag':true,'tag-active':active.tag === index}" :src="item.path" @click="handleTag(item,index)" :title="item.name" >
                        </div>
                        <div class="copyright">@新科汇智科技有限公司 版权所有</div>
                    </div>
                    <!-- <div class="top-box">
                        <div class="box-container">
                            <div>监测预警</div>
                            <div class="num">0</div>
                            <div>边坡监测系统</div>
                        </div>
                        <div class="box-container">
                            <div>监测预警</div>
                            <div class="num">3</div>
                            <div>桥梁监测系统</div>
                        </div>
                        <div class="box-container">
                            <div>监测预警</div>
                            <div class="num">12</div>
                            <div>气象精灵系统</div>
                        </div>
                    </div> -->
                    <!-- <div class="bottom-box">
                        <div class="name">移动设备在线</div>
                        <div class="box-info">
                            <div class="box-container">
                                <div class="circle">
                                    <div class="logo"></div>
                                    <div class="num">3 <span>/ 5</span></div>
                                </div>
                                <div class="name">手提无人机工作站</div>
                            </div>
                            <div class="box-container">
                                <div class="circle">
                                    <div class="logo"></div>
                                    <div class="num">3 <span>/ 8</span></div>
                                </div>
                                <div class="name">米诺隧道机器人</div>
                            </div>
                            <div class="box-container">
                                <div class="circle">
                                    <div class="logo"></div>
                                    <div class="num">5 <span>/ 15</span></div>
                                </div>
                                <div class="name">移动车载视频</div>
                            </div>
                            <div class="box-container">
                                <div class="circle">
                                    <div class="logo"></div>
                                    <div class="num">1  <span>/ 3</span></div>
                                </div>
                                <div class="name">安E巡</div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AXIOS from 'axios';
import { reactive, toRefs } from '@vue/reactivity'
import { onBeforeUnmount, onMounted } from '@vue/runtime-core'
import { ElMessage } from 'element-plus'
export default {
    setup(){
        const state = reactive({
            devPointData:[
               { top:'15%', left:'35%',path:require('@/assets/img/tag/pinganhua.jpg'),name:'平安花',superior:'平安花安全预警系统'},
               { top:'36%', left:'12.9%',path:require('@/assets/img/tag/bianpo.jpg'),name:'边坡监测系统',superior:'边坡监测系统'},
               { top:'36%', left:'19.6%',path:require('@/assets/img/tag/anquanshaobing.jpg'),name: "安全哨卫",superior:'安全哨卫管理平台'},
               { top:'52%', left:'13.7%',path:require('@/assets/img/tag/zhendong.jpg'),name: "震动传感器",superior:'桥梁健康监测'},
               { top:'50.5%', left:'19.9%',path:require('@/assets/img/tag/shengwen.jpg'),name: "声纹传感器",superior:'桥梁健康监测'},
               { top:'52%', left:'26.2%',path:require('@/assets/img/tag/qiaojianwangguan.jpg'),name: "桥检接入网关",superior:'桥梁健康监测'},
               { top:'50.6%', left:'41%',path:require('@/assets/img/tag/minuojiqiren.jpg'),name: "米诺隧道巡检机器人",superior:'米诺隧道巡检系统'},
               { top:'73%', left:'4.3%',path:require('@/assets/img/tag/qixiangjingling.jpg'),name: "气象精灵",superior:'气象精灵监测系统'},
               { top:'74%', left:'18%',path:require('@/assets/img/tag/wurenjifengchao.jpg'),name: "无人机蜂巢",superior:'无人机空管系统'},
               { top:'88%', left:'5.3%',path:require('@/assets/img/tag/wandaoyujing.jpg'),name: "弯道预警",superior:'弯道预警平台'},
               { top:'74%', left:'41%',path:require('@/assets/img/tag/zhihuiluzhuang.jpg'),name: "智慧路桩",superior:'合流区预警平台'},
               { top:'72%', left:'54.5%',path:require('@/assets/img/tag/qixiangjingling.jpg'),name: "气象精灵",superior:'气象精灵监测系统'},
               { top:'64%', left:'49%',path:require('@/assets/img/tag/wurenjifengchao.jpg'),name: "无人机蜂巢",superior:'无人机空管系统'},
               { top:'40%', left:'52.5%',path:require('@/assets/img/tag/jiaotiaoxitong.jpg'),name: "交调系统",superior:'交通流量系统'},
               { top:'39.3%', left:'73%',path:require('@/assets/img/tag/cesuyi.jpg'),name: "便携测速仪",superior:null},
               { top:'57.4%', left:'74.5%',path:require('@/assets/img/tag/pinganhua.jpg'),name:'平安花',superior:'平安花安全预警系统'},
               { top:'50%', left:'67.8%',path:require('@/assets/img/tag/tongbutuxiangcaijiqi.jpg'),name:'同步图像采集器',superior:null},
               { top:'2%', left:'81.2%',path:require('@/assets/img/tag/shoutiwurenjigongzuozhan.jpg'),name:'手提无人机工作站',superior:'无人机空管系统'},
               { top:'6%', left:'86.1%',path:require('@/assets/img/tag/yianbo.jpg'),name:'易安泊采集终端',superior:null},
            ],
            navList:[],
            active:{
                tag:null,
                dev:null,
            },
            dateTime:{
                date:null,
                time:null,
                week:null
            },
            loading:true
        })
        // 选择设备
        const selectDev = (item,index) => {
            console.log(item,index);
            state.active.dev = index;
            if(item.path){
                window.open(item.path);
            }else{
                ElMessage.warning('未配置地址，敬请期待！');
            }
        }
        // 跳转相应的条目
        const goItem = (item) => {
            if(state.loading){
                return ElMessage.warning('请稍等,接口数据查询中...')
            }
            if(item.superior){
                const findItem = state.navList.find( v => v.meta.title === item.superior)
                console.log(findItem)
                if(findItem && findItem.path){
                    window.open(findItem.path);
                }else{
                    ElMessage.error('暂无访问权限！');
                }
            }else{
                ElMessage.warning('敬请期待！');
            }
        }
        // 操作标记
        const handleTag = (item,index) => {
            state.active.tag = index;
        }
        let timer = null;
        const getTime = () => {
            //定义个个时间变量
            const date = new Date();
            const year = date.getFullYear();
            const mth = date.getMonth() + 1;
            const d = date.getDate();
            const h = date.getHours();
            const m = date.getMinutes();
            const s = date.getSeconds();
            const week = date.getDay();
            //定义拼接变量,变量调用转换两位数函数
            // const time = "现在是" + year + "年" + fn(mth) + "月" + fn(d) + "日" + fn(h) + ":" + fn(m) +  ":" + fn(s) +"（星期"+week+"）";
            // console.log(time)
            state.dateTime = {
                date:`${year}-${fn(mth)}-${fn(d)}`,
                time:`${fn(h)}:${fn(m)}:${fn(s)}`,
                week:fnWeek(week)
            }
            //定义函数，将一位数"1"转为两位数"01"
            function fn(str) {
                str < 10 ? str = "0" + str : str;
                return str;
            }
            //定义函数，星期判断
            function fnWeek(str) {
                switch(str){
                    case 0:
                        str = '日';
                        break;
                    case 1:
                        str = '一';
                        break;
                    case 2:
                        str = '二';
                        break;
                    case 3:
                        str = '三';
                        break;
                    case 4:
                        str = '四';
                        break;
                    case 5:
                        str = '五';
                        break;
                    case 6:
                        str = '六';
                        break;
                    default:
                        str = '日';
                        break;
                }
                return str
            }
        }

        // 获取左侧列表
        const getLeftNavList = () => {
            AXIOS.get('/auth/menu/getRouteMenu').then(res => {
                console.log('res',res)
                if(res.data.code === 200){
                    state.navList = res.data.data.menuList;
                }
                state.loading = false;
            })
        }

        onMounted(()=>{
            timer = setInterval(getTime, 1000);
            getLeftNavList();
        })
        onBeforeUnmount(()=>{
            clearInterval(timer);
        })

        return {
            ...toRefs(state),
            handleTag,
            selectDev,
            goItem
        }
    },
    mounted () {
        let token = localStorage.getItem('Tit-token');
            if (token == null) {
                this.$message.info('Tit-token已失效，请重新登录！')
                this.$router.replace({
                    path: '/'
                });
            }
            console.log('token',token)
        }
}
</script>

<style lang="scss" scoped>
.home{
  background-color: #152c44;
  background: url('../../assets/img/bgImg.png') no-repeat;
  background-size: cover;
  padding: 10px;
  height: calc(100% - 20px );
  .header{
      height:calc(15vh - 10px);
      background: url('../../assets/img/titleBgImg.png') no-repeat;
      background-size: contain;
      .title{
            text-align: left;
            font-weight: bolder;
            letter-spacing: 5px;
            line-height: 4;
            color: #fff;
            font-size: 1.8rem;
            padding-left: 180px;
        }
        .date-time{
            position: absolute;
            right: 2%;
            top: 45%;
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 1.2rem;
            font-weight: bold;
            &>div{
                margin-bottom: 10px;
            }
            .week{
                font-size: .9rem;
                padding-left: 5px;
            }
        }
  }
}

.content{
    width: 100%;
    height: calc(85vh - 11px);
    display: flex;
    .left{
        background: url('../../assets/img/contentLeftBg.png') no-repeat;
        background-size: 100% 100%;
        width: calc(15% - 10px);
        margin-right: 10px;
        // border: 1px solid rgb(110, 110, 110);
        height: 100%;
        overflow-y: auto;
        .container{
            padding: 10px;
            .title{
                background: url('../../assets/img/btnBg.png') no-repeat;
                background-size: 100% 100%;
                color: #fbfbfb;
                font-size: 1.1rem;
                line-height: 2.5;
                cursor: pointer;
                text-align: left;
                // padding-left: 20px;
                padding: 6px 20px;
                height: inherit;
                &:hover{
                    color: #fff;
                    background: url('../../assets/img/btnBgActive.png') no-repeat;
                    background-size: 100% 100%;
                }
            }
            .title-active{
                color: #fff;
                background: url('../../assets/img/btnBgActive.png') no-repeat;
                background-size: 100% 100%;
            }
        }
    }
    .middle{
        width: 85%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .bottom{
            background: url('../../assets/img/contentRightBg.png') no-repeat;
            padding: 4vh;
            background-size: 100% 100%;
            height: calc(100% - 8vh);
            // border: 1px solid rgb(95, 95, 95);
            display: flex;
            justify-content: center;
            align-items: center;
            .container{
                height: 100%;
                // width: 100%;
                .img{
                    width: 100%;
                    height: 100%;
                }
                .point{
                    position: absolute;
                    top: 0;
                    .tag{
                        width: 45px;
                        height: 45px;
                        border-radius: 50%;
                        transition: all 1.5s;
                        border: 5px solid #7af8bd;
                        &:hover{
                            cursor: pointer;
                            transform: scale(2);
                        }
                    }
                    .tag-active{
                        width: 45px;
                        height: 45px;
                        border-radius: 50%;
                        transition: all 2s;
                        border: 5px solid #00ff62;
                        &:hover{
                            cursor: pointer;
                            transform: scale(2);
                        }
                    }
                }
                .copyright{
                    position: absolute;
                    bottom: 1.5%;
                    left: 45%;
                    color: #c5c5c5;
                    font-size: .75rem;
                    letter-spacing: 2px;
                }
            }
            .top-box{
                position: absolute;
                top: 5%;
                left: 2%;
                display: flex;
                .box-container{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    // border: 2px solid #7af8bd;
                    margin-right: 20px;
                    color: #fff;
                    padding: 10px 20px;
                    border-radius: 10px;
                    font-size: .75rem;
                    background: url('../../assets/img/smallBlock.png') no-repeat;
                    background-size: 100% 100%;
                    .num{
                        font-size: 1.8rem;
                        padding: 5px 0;
                        font-weight: bold;
                        color: #7af8bd;
                    }
                }
            }
            .bottom-box{
                position: absolute;
                bottom: 8%;
                right: 2%;
                background: url('../../assets/img/middleBlock.png') no-repeat;
                padding: 12px;
                background-size: 100% 100%;
                .name{
                    color: #fff;
                    border-left: 6px solid #ef3232;
                    padding-left: 8px;
                    font-size: 0.85rem;
                }
                .box-info{
                    display: flex;
                    .box-container{
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        margin-right: 15px;
                        padding: 10px 10px;
                        font-size: .75rem;
                        color: #fff;
                        padding-left: 20px;
                        .circle{
                            display: flex;
                            align-items: center;
                            .logo{
                                width: 18px;
                                height: 18px;
                                border-radius: 50%;
                                background-color: #7af8bd;
                                margin-right: 5px;
                            }
                            .num{
                                color: #7af8bd;
                                font-weight: bold;
                                font-size: 1.5rem;
                                span{
                                    color: #fff;
                                    font-size: 1.1rem;
                                }
                            }
                        }
                        .name{
                            margin-top: 10px;
                            border-left: none;
                            font-size: .7rem;
                            padding-left: 0;
                        }
                    }
                }
                
            }
        }
    }
}
</style>