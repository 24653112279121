<template>
  <router-view />
</template>

<style lang="scss">
body,html{
  height: 100%;
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  height: 100%;
  // background-color: #96c3f1;
}
@font-face {
  font-family: 'iconfont';  /* Project id 2514876 */
  src: url('//at.alicdn.com/t/font_2514876_ows3rm3kl5s.woff2?t=1648740113439') format('woff2'),
       url('//at.alicdn.com/t/font_2514876_ows3rm3kl5s.woff?t=1648740113439') format('woff'),
       url('//at.alicdn.com/t/font_2514876_ows3rm3kl5s.ttf?t=1648740113439') format('truetype');
}
.iconfont{
  font-family:"iconfont" !important;
  font-size:16px;font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.glo-relative{
  position: relative;
}
</style>
