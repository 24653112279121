import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn';
import axios from 'axios';
import tool from './network/tool'
import http from "./network/request"

document.title = '新科智慧云平台';

const app = createApp(App)
app.config.globalProperties.$AXIOS = axios;
app.config.globalProperties.$TOOL = tool;
app.config.globalProperties.$HTTP = http;
app.use(ElementPlus, { size: 'small',locale})
app.use(router)

app.mount('#app')

