import { createRouter, createWebHashHistory } from 'vue-router'
import home from '../views/home/home.vue'
import index from '../views/index/index.vue'
import login from '../component/Login.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: login
  },
  {
    path: '/home',
    name: 'home',
    component: home
  },
  {
    path: '/index',
    name: 'index',
    component: index
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
