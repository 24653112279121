<template>
    <div class="home">
        <div class="header glo-relative">
            <div class="title">新科智慧云平台</div>
            <div class="date-time">
                <div>{{dateTime.date}}</div>
                <div>{{dateTime.time}}</div>
            </div>
        </div>
        <div class="content">
            <div class="left">
                <div class="container">
                    <div class="title" v-for="(item,index) in navList" :key="index">● {{item.name}}</div>
                </div>
                <!-- <div class="left-0">
                    <div class="container">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <div class="row-info">物联网设备:<span>12</span></div>
                                <div class="row-info">平安花设备:<span>12</span></div>
                                <div class="row-info">机器人设备:<span>12</span></div>
                                <div class="row-info">机器人设备:<span>12</span></div>
                            </el-col>
                            <el-col :span="12">
                                <div class="row-info">物联网安全级别:<span>最高</span></div>
                                <div class="row-info">平安花安全级别:<span>最优</span></div>
                                <div class="row-info">机器人安全级别:<span>最高</span></div>
                                <div class="row-info">机器人安全级别:<span>一般</span></div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div class="left-1">
                    <div class="container">
                        <div><span>物联网设备</span><el-progress :percentage="50" /></div>
                        <div><span>平安花设备</span><el-progress :percentage="100" :format="format" /></div>
                        <div><span>机器人设备</span><el-progress :percentage="100" status="success" /></div>
                        <div><span>机器人设备</span><el-progress :percentage="100" status="warning" /></div>
                        <div><span>机器人设备</span><el-progress :percentage="50" status="exception" /></div>
                    </div>
                </div>
                <div class="left-2"></div> -->
            </div>
            <div class="middle">
                <!-- <div class="top">
                    <div class="container">
                        <div class="line"></div>
                        <div class="row-info">
                            <el-row :gutter="140">
                                <el-col :span="4" class="block-area">
                                    <div class="point"></div>
                                    <div class="block-box-info">
                                        <div><i class="iconfont">&#xe61b;</i></div>
                                        <div class="name">物联网灯</div>
                                    </div>
                                </el-col>
                                <el-col :span="4" class="block-area">
                                    <div class="point"></div>
                                    <div class="block-box-info">
                                        <div><i class="iconfont">&#xe61b;</i></div>
                                        <div class="name">平安花</div>
                                    </div>
                                </el-col>
                                <el-col :span="4" class="block-area active">
                                    <div class="point"></div>
                                    <div class="block-box-info">
                                        <div><i class="iconfont">&#xe61b;</i></div>
                                        <div class="name">机器人A</div>
                                    </div>
                                </el-col>
                                <el-col :span="4" class="block-area">
                                    <div class="point"></div>
                                    <div class="block-box-info">
                                        <div><i class="iconfont">&#xe61b;</i></div>
                                        <div class="name">机器人B</div>
                                    </div>
                                </el-col>
                                <el-col :span="4" class="block-area">
                                    <div class="point"></div>
                                    <div class="block-box-info">
                                        <div><i class="iconfont">&#xe61b;</i></div>
                                        <div class="name">机器人C</div>
                                    </div>
                                </el-col>
                                <el-col :span="4" class="block-area">
                                    <div class="point"></div>
                                    <div class="block-box-info">
                                        <div><i class="iconfont">&#xe61b;</i></div>
                                        <div class="name">机器人D</div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </div> -->
                <div class="bottom glo-relative">
                    <div class="container glo-relative">
                        <img class="img" src="../../assets/img/home1.png">
                        <div class="point" v-for="(item,index) in devPointData" :key="index" :style="{top:item.top,left:item.left}">
                            <img :class="{'tag':true,'tag-active':active.tag === index}" :src="item.path" @click="handleTag(item,index)">
                        </div>
                        <div class="copyright">@新科汇智科技有限公司 版权所有</div>
                    </div>
                    <div class="top-box">
                        <div class="box-container">
                            <div>监测预警</div>
                            <div class="num">0</div>
                            <div>边坡监测系统</div>
                        </div>
                        <div class="box-container">
                            <div>监测预警</div>
                            <div class="num">3</div>
                            <div>桥梁监测系统</div>
                        </div>
                        <div class="box-container">
                            <div>监测预警</div>
                            <div class="num">12</div>
                            <div>气象精灵系统</div>
                        </div>
                    </div>
                    <div class="bottom-box">
                        <div class="name">移动设备在线</div>
                        <div class="box-info">
                            <div class="box-container">
                                <div class="circle">
                                    <div class="logo"></div>
                                    <div class="num">3 <span>/ 5</span></div>
                                </div>
                                <div class="name">手提无人机工作站</div>
                            </div>
                            <div class="box-container">
                                <div class="circle">
                                    <div class="logo"></div>
                                    <div class="num">3 <span>/ 8</span></div>
                                </div>
                                <div class="name">米诺隧道机器人</div>
                            </div>
                            <div class="box-container">
                                <div class="circle">
                                    <div class="logo"></div>
                                    <div class="num">5 <span>/ 15</span></div>
                                </div>
                                <div class="name">移动车载视频</div>
                            </div>
                            <div class="box-container">
                                <div class="circle">
                                    <div class="logo"></div>
                                    <div class="num">1  <span>/ 3</span></div>
                                </div>
                                <div class="name">安E巡</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="right">
                <div class="right-0"></div>
                <div class="right-1"></div>
            </div> -->
        </div>
    </div>
</template>

<script>
import { reactive, toRefs } from '@vue/reactivity'
import { onBeforeUnmount, onMounted } from '@vue/runtime-core'
export default {
    setup(){
        const state = reactive({
            devPointData:[
               { top:'17%', left:'36%',path:require('@/assets/img/tag/pinganhua.jpg')},
               { top:'36%', left:'13.9%',path:require('@/assets/img/tag/bianpo.jpg')},
               { top:'36%', left:'20.6%',path:require('@/assets/img/tag/anquanshaobing.jpg')},
               { top:'54%', left:'14.7%',path:require('@/assets/img/tag/zhendong.jpg')},
               { top:'54%', left:'20.9%',path:require('@/assets/img/tag/shengwen.jpg')},
               { top:'54%', left:'27.2%',path:require('@/assets/img/tag/qiaojianwangguan.jpg')},
               { top:'52.6%', left:'41%',path:require('@/assets/img/tag/minuojiqiren.jpg')},
               { top:'72%', left:'5.3%',path:require('@/assets/img/tag/qixiangjingling.jpg')},
               { top:'76%', left:'18%',path:require('@/assets/img/tag/wurenjifengchao.jpg')},
               { top:'88%', left:'5.3%',path:require('@/assets/img/tag/wandaoyujing.jpg')},
               { top:'77%', left:'42%',path:require('@/assets/img/tag/zhihuiluzhuang.jpg')},
               { top:'76%', left:'55.5%',path:require('@/assets/img/tag/qixiangjingling.jpg')},
               { top:'66%', left:'50%',path:require('@/assets/img/tag/wurenjifengchao.jpg')},
               { top:'42%', left:'55.5%',path:require('@/assets/img/tag/jiaotiaoxitong.jpg')},
               { top:'39.2%', left:'74.2%',path:require('@/assets/img/tag/tongbutuxiangcaijiqi.jpg')},
               { top:'61%', left:'75.5%',path:require('@/assets/img/tag/pinganhua.jpg')},
               { top:'56%', left:'68.8%',path:require('@/assets/img/tag/tongbutuxiangcaijiqi.jpg')},
               { top:'4%', left:'82.2%',path:require('@/assets/img/tag/shoutiwurenjigongzuozhan.jpg')},
               { top:'9%', left:'87.1%',path:require('@/assets/img/tag/yianbo.jpg')},
            ],
            navList:[
               {name: "无人机蜂巢系统"},
               {name: "无人机手提工作站"},
               {name: "边坡检测系统"},
               {name: "桥梁健康监测"},
               {name: "气象精灵系统"},
               {name: "雾区引导系统"},
               {name: "合流区预警系统"},
               {name: "弯道预警系统"},
               {name: "交通流量系统"},
               {name: "米诺隧道巡检系统"},
               {name: "安全哨卫系统"},
               {name: "路侧抓拍系统"},
               {name: "移动车载视频系统"},
               {name: "违停巡检系统"},
               {name: "平安花安全预警系统"}
            ],
            active:{
                tag:null
            },
            dateTime:{
                date:null,
                time:null
            }
        })
        // 操作标记
        const handleTag = (item,index) => {
            console.log(item,index);
            state.active.tag = index;
        }
        let timer = null;
        const getTime = () => {
            //定义个个时间变量
            const date = new Date();
            const year = date.getFullYear();
            const mth = date.getMonth() + 1;
            const d = date.getDate();
            const h = date.getHours();
            const m = date.getMinutes();
            const s = date.getSeconds();
            const week = date.getDay();
            //定义拼接变量,变量调用转换两位数函数
            // const time = "现在是" + year + "年" + fn(mth) + "月" + fn(d) + "日" + fn(h) + ":" + fn(m) +  ":" + fn(s) +"（星期"+week+"）";
            // console.log(time)
            state.dateTime = {
                date:`${year}-${fn(mth)}-${fn(d)}`,
                time:`${fn(h)}:${fn(m)}:${fn(s)}`
            }
            //定义函数，将一位数"1"转为两位数"01"
            function fn(str) {
                str < 10 ? str = "0" + str : str;
                return str;
            }
        }

        onMounted(()=>{
            timer = setInterval(getTime, 1000);
        })
        onBeforeUnmount(()=>{
            clearInterval(timer);
        })

        return {
            ...toRefs(state),
            handleTag
        }
    }
}
</script>

<style lang="scss" scoped>
.home{
  background-color: #152c44;
  padding: 10px;
  height: calc(100% - 20px );
  .header{
      height:calc(8vh - 10px);
      .title{
            text-align: center;
            font-weight: bolder;
            letter-spacing: 2px;
            line-height: 3;
            color: #fff;
            font-size: 1.5rem;
        }
        .date-time{
            position: absolute;
            right: 3%;
            top: 14%;
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: .95rem;
            font-weight: bold;
            &>div{
                margin-bottom: 10px;
            }
        }
  }
}

.content{
    width: 100%;
    height: calc(92vh - 11px);
    display: flex;
    // border-top: 1px solid #f0f0f0;
    .left{
        width: calc(15% - 10px);
        margin-right: 10px;
        border: 1px solid rgb(110, 110, 110);
        height: 100%;
        .container{
            padding: 10px;
            .title{
                color: #fff;
                font-size: 1.2rem;
                color: #fbfbfb;
                font-size: 1.1rem;
                line-height: 3;
                cursor: pointer;
                text-align: left;
                padding-left: 20px;
                height: inherit;
                &:hover{
                    color: #7af8bd;
                }
            }
        }
        // .left-0{
        //     margin-top: 10px;
        //     height: calc(20% - 20px);
        //     margin-bottom: 10px;
        //     border: 1px solid rgb(110, 110, 110);
        //     .container{
        //         padding: 10px;
        //         .row-info{
        //             padding: 4px;
        //             border-bottom: 1px solid #4c9cec;
        //             color: #fff;
        //             span{
        //                 font-weight: bold;
        //                 color: #a4f5c6;
        //             }
        //         }
        //     }
        // }
        // .left-1{
        //     height: calc(30% - 10px);
        //     margin-bottom: 10px;
        //     border: 1px solid rgb(110, 110, 110);
        //     .container{
        //         padding: 10px;
        //         color: #fff;
        //         .el-progress--line {
        //             margin-bottom: 12px;
        //             width: 100%;
        //         }
        //     } 
        // }
        // .left-2{
        //     height: calc(50% - 10px);
        //     margin-bottom: 10px;
        //     border: 1px solid rgb(110, 110, 110);
        // }
    }
    .middle{
        width: 85%;
        height: 100%;
        display: flex;
        flex-direction: column;
        // .top{
        //     margin: 10px 0;
        //     height: calc(18% - 20px);
        //     border: 1px solid rgb(95, 95, 95);
        //     .container{
        //         .block-area{
        //             margin-top: -2.5%;
        //         }
        //         .line{
        //             height: 4px;
        //             margin: 20px;
        //             background-color: #a7bee6;
        //         }
        //         .row-info{
        //             margin: 20px;
        //             .point{
        //                 height: 16px;
        //                 width: 16px;
        //                 border-radius: 50%;
        //                 background-color: #a7bee6;
        //                 text-align: center;
        //                 margin: 0 auto 5px auto;
        //             }
        //             .block-box-info{
        //                 border: 3px solid #a7bee6;
        //                 border-radius: 10px;
        //                 height: 60px;
        //                 display: flex;
        //                 flex-direction: column;
        //                 justify-content: center;
        //                 align-items: center;
        //                 .iconfont{
        //                     font-size: 1.3rem;
        //                     color: #a7bee6;
        //                 }
        //                 .name{
        //                     color: #a7bee6;
        //                     font-size: .75rem;
        //                 }
        //             }
        //             .active{
        //                 .point{
        //                     background-color: #7af8bd;
        //                     text-align: center;
        //                     margin: 0 auto 5px auto;
        //                 }
        //                 .block-box-info{
        //                     border: 3px solid #7af8bd;
        //                     .iconfont{
        //                         color: #7af8bd;
        //                     }
        //                     .name{
        //                         color: #7af8bd;
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
        .bottom{
            height: 100%;
            border: 1px solid rgb(95, 95, 95);
            display: flex;
            justify-content: center;
            align-items: center;
            .container{
                height: 100%;
                // width: 100%;
                .img{
                    width: 100%;
                    height: 100%;
                }
                .point{
                    position: absolute;
                    top: 0;
                    .tag{
                        width: 45px;
                        height: 45px;
                        border-radius: 50%;
                        transition: all 1.5s;
                        border: 5px solid #7af8bd;
                        &:hover{
                            cursor: pointer;
                            transform: scale(2);
                        }
                    }
                    .tag-active{
                        width: 45px;
                        height: 45px;
                        border-radius: 50%;
                        transition: all 2s;
                        border: 5px solid #00ff62;
                        &:hover{
                            cursor: pointer;
                            transform: scale(2);
                        }
                    }
                }
                .copyright{
                    position: absolute;
                    bottom: 2%;
                    left: 45%;
                    color: #c5c5c5;
                    font-size: .75rem;
                }
            }
            .top-box{
                position: absolute;
                top: 2%;
                left: 2%;
                display: flex;
                .box-container{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border: 2px solid #7af8bd;
                    margin-right: 20px;
                    color: #fff;
                    padding: 10px 20px;
                    border-radius: 10px;
                    font-size: .75rem;
                    .num{
                        font-size: 1.8rem;
                        padding: 5px 0;
                        font-weight: bold;
                        color: #7af8bd;
                    }
                }
            }
            .bottom-box{
                position: absolute;
                bottom: 2%;
                right: 2%;
                .name{
                    color: #fff;
                    border-left: 6px solid #ef3232;
                    padding-left: 8px;
                    font-size: 0.85rem;
                }
                .box-info{
                    display: flex;
                    .box-container{
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        margin-right: 15px;
                        padding: 10px 10px;
                        font-size: .75rem;
                        color: #fff;
                        padding-left: 20px;
                        .circle{
                            display: flex;
                            align-items: center;
                            .logo{
                                width: 18px;
                                height: 18px;
                                border-radius: 50%;
                                background-color: #7af8bd;
                                margin-right: 5px;
                            }
                            .num{
                                color: #7af8bd;
                                font-weight: bold;
                                font-size: 1.5rem;
                                span{
                                    color: #fff;
                                    font-size: 1.1rem;
                                }
                            }
                        }
                        .name{
                            margin-top: 10px;
                            border-left: none;
                            font-size: .7rem;
                            padding-left: 0;
                        }
                    }
                }
                
            }
        }
    }
    // .right{
    //     width: calc(20% - 10px);
    //     height: 100%;
    //     padding-left: 10px;
    //     .right-0{
    //         margin-top: 10px;
    //         height: calc(45% - 20px);
    //         margin-bottom: 10px;
    //         border: 1px solid rgb(110, 110, 110);
    //     }
    //     .right-1{
    //         height: calc(55% - 10px);
    //         margin-bottom: 10px;
    //         border: 1px solid rgb(110, 110, 110);
    //     }
    // }
}
</style>